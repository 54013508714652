<h1>
  <app-logo
    [type]="verticalLogo ? 'vertical' : 'horizontal-short'"
    fill="currentColor"
  ></app-logo>
</h1>
<div class="menu">
  @if (notHome | async) {
    <app-link routerLink="/">Domů</app-link>
  } @else if (authService.loggedIn()) {
    <app-button (click)="authService.signOut()">Odhlásit se</app-button>
  } @else {
    <app-link routerLink="/prihlasit">Přihlásit se</app-link>
  }
</div>
