@if (updateService.updateAvailable | async) {
  <div class="update-banner">
    <p>Byla vydána nová verze aplikace</p>
    <app-button kind="filled" (click)="reload()">Aktualizovat</app-button>
  </div>
}

<app-navigation-bar></app-navigation-bar>
<main>
  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>
