import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logo.component.svg',
})
export class LogoComponent {
  @Input() type: 'vertical' | 'horizontal' | 'horizontal-short' = 'vertical';
  @Input() fill: string = 'black';
  //@Input() fill: string = 'white';
}
