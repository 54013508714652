import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';
import packageInfo from '../package.json';

import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  provideFunctions,
  getFunctions,
  connectFunctionsEmulator,
} from '@angular/fire/functions';
import {
  provideFirestore,
  getFirestore,
  connectFirestoreEmulator,
} from '@angular/fire/firestore';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
import {
  ErrorHandler,
  APP_INITIALIZER,
  isDevMode,
  importProvidersFrom, LOCALE_ID,
} from '@angular/core';
import localeCs from '@angular/common/locales/cs';
import { registerLocaleData } from "@angular/common";
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { DialogModule } from '@angular/cdk/dialog';

registerLocaleData(localeCs);

Sentry.init({
  dsn: 'https://ca4f19492ba745de88ffe7b497a3139a@iguana.observer/29',
  environment: environment.name,
  enabled: environment.production,
  release: `loyal-fox@${packageInfo.version}`,
  tracePropagationTargets: ['localhost', environment.functionsUrl],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});

let importModules = [];
if (environment.name === 'Production') {
  importModules.push(NgxHotjarModule.forRoot(environment.hotjarId));
  importModules.push(NgxHotjarRouterModule);
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => {
        const auth = getAuth(getApp());
        if (environment.useEmulators) {
          connectAuthEmulator(auth, 'http://localhost:9099', {
            disableWarnings: false,
          });
        }
        return auth;
      }),
      provideFirestore(() => {
        const firestore = getFirestore();
        if (environment.useEmulators) {
          connectFirestoreEmulator(firestore, 'localhost', 8080);
        }
        return firestore;
      }),
      provideFunctions(() => {
        const functions = getFunctions(getApp(), 'europe-west1');
        if (environment.useEmulators) {
          connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        return functions;
      }),
      provideStorage(() => {
        const storage = getStorage(getApp());
        if (environment.useEmulators) {
          connectStorageEmulator(storage, 'localhost', 9199);
        }
        return storage;
      }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      ...importModules,
      DialogModule
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.name !== 'Production',
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'cs-CZ' }
  ],
}).catch((err) => console.error(err));
