import { Component, HostBinding, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationStart, Router } from "@angular/router";
import { RouterModule } from "@angular/router";

import { ButtonComponent } from "../button/button.component";
import { LogoComponent } from "../logo/logo.component";

import { AuthService } from "../../services/auth/auth.service";
import { combineLatest, filter, map, Observable } from "rxjs";
import { LinkComponent } from "../link/link.component";
import { UserType } from "src/models/User";

@Component({
  selector: "app-navigation-bar",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ButtonComponent,
    LinkComponent,
    LogoComponent,
  ],
  templateUrl: "./navigation-bar.component.html",
  styleUrls: ["./navigation-bar.component.scss"],
})
export class NavigationBarComponent {
  public authService = inject(AuthService);
  private router = inject(Router);

  notHome: Observable<boolean> = this.router.events.pipe(
    filter((event) => event instanceof NavigationStart),
    map((event) => (event as NavigationStart).url.split("?")[0] !== "/")
  );

  @HostBinding("class.vertical") verticalLogo = false;
  @HostBinding("class.cashier") isCashier = false;

  constructor() {
    // watch for route changes and if User is logged in
    combineLatest([this.notHome, this.authService.$loggedIn]).subscribe(
      ([notHome, loggedIn]) => {
        if (notHome) {
          this.verticalLogo = false;
          return;
        }

        this.verticalLogo = !loggedIn;
      }
    );
  }

  ngOnInit() {
    // watch User object and see, if they have Cashier role
    this.authService.$firebaseUser.subscribe(async () => {
      this.isCashier = await this.authService.hasRole(UserType.cashier);
    });
  }

  // todo implement notHome unsubscribe in case navigation bar might not be present in every route
  // ngOnDestroy() {}
}
