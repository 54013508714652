import { Component } from '@angular/core';
import { UpdateService } from './services/update/update.service';
import { FooterComponent } from './components/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { ButtonComponent } from './components/button/button.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ButtonComponent,
        NavigationBarComponent,
        RouterOutlet,
        FooterComponent,
        AsyncPipe,
    ],
})
export class AppComponent {
  constructor(public updateService: UpdateService) {}

  public reload() {
    window.location.reload();
  }
}
