{
  "name": "loyal-fox",
  "version": "0.1.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build:prod": "ng build --configuration production",
    "build:staging": "ng build --configuration staging",
    "watch": "ng build --watch --configuration development",
    "deploy:prod": "firebase use prod && firebase deploy -P prod --only hosting:prod",
    "deploy:staging": "firebase use staging && firebase deploy -P staging --only hosting:staging",
    "build-deploy:prod": "firebase use prod && ng build --configuration production && firebase deploy -P prod --only hosting:prod",
    "build-deploy:staging": "firebase use staging && ng build --configuration staging && firebase deploy -P staging --only hosting:staging",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.8",
    "@angular/cdk": "^17.0.4",
    "@angular/common": "^17.0.8",
    "@angular/compiler": "^17.0.8",
    "@angular/core": "^17.0.8",
    "@angular/fire": "^17.0.0",
    "@angular/forms": "^17.0.8",
    "@angular/platform-browser": "^17.0.8",
    "@angular/platform-browser-dynamic": "^17.0.8",
    "@angular/router": "^17.0.8",
    "@angular/service-worker": "^17.0.8",
    "@sentry/angular-ivy": "^7.91.0",
    "@zxing/ngx-scanner": "^17.0.0",
    "angularx-qrcode": "^17.0.0",
    "marked": "^11.1.1",
    "ngx-hotjar": "^11.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.6.2",
    "zone.js": "^0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.8",
    "@angular/cli": "~17.0.8",
    "@angular/compiler-cli": "^17.0.8",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "openai": "^4.24.1",
    "stripe": "^14.10.0",
    "typescript": "^5.2.2"
  }
}
